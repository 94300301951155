var _api_root = '/api/';
// _api_root = "https://xh-h5.73lt.com/api/";
// _api_root = '/api/';

var api = {
    get: {
        singleSreach: _api_root + `statistics/front/singleSreach`,
        eventRanksInfo: _api_root + `statistics/front/eventRanksInfo`,
        teamScores: _api_root + `statistics/front/pageTeamScores`,
        singleScores: _api_root + `statistics/front/pageSingleScores`,
        frontSearchByEventId: _api_root + `event-mgr/eventParticipantList/frontSearchByEventId`,
        certInfo: _api_root + `statistics/front/certInfo`,
        getEvent: _api_root + `statistics/front/getEvent`,
        getProject: _api_root + `statistics/front/getProject`,
        singleScoresPage: _api_root + `statistics/front/singleScoresPage`,
        teamScoresPage: _api_root + `statistics/front/teamScoresPage`,
        largeScreenDetail: _api_root + `event-mgr/largeScreen/detail`,
        listLargeScreenRanksData: _api_root + `statistics/front/listLargeScreenRanksData`,
        listIndividualScoresListByTeamId: _api_root + `statistics/front/getIndividualScoresListByTeamId`,
        frontSearch: _api_root + `event-mgr/eventParticipantList/frontSearch`,
        getCurrentProjectId: _api_root + `event-mgr/eventParticipantList/getCurrentProjectId`,
        certPreview: _api_root + `statistics/front/certInfo/preview`,
        getIndividualScoresByBibNumbers: _api_root + `statistics/front/getIndividualScoresByBibNumbers`,
        getTeamScoresByTeamIds: _api_root + `statistics/front/getTeamScoresByTeamIds`,
        getIndividualScoresById: _api_root + `statistics/front/getIndividualScoresById`,
        eventList: _api_root + `event-mgr/event/frontPage`,
        electronList: _api_root + `statistics/front/electron`,
        roleCertList: _api_root + `statistics/front/roleCert`,
    },
    post: {
        // postLogin: _api_root + "api/v1/auth/login",
    },

};
export default api;
