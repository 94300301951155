<template>
  <div class="content">
    <div class="title-body">
      <div class="title">赛事列表</div>
    </div>
    <div class="content-inner" v-if="tableData.length">
      <div class="form">
        <el-form :inline="true" class="demo-form-inline">
          <el-row>
            <el-col :span="6" :offset="18">
              <div class="grid-content sreach-box" style="margin-right: 10px">
                <el-input v-model="eventName" placeholder="请输入赛事名称"></el-input>
                <el-button type="primary" class="btn" @click="loadTableData">查询</el-button>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <el-row>
        <el-col :span="8" v-for="(item) in tableData" :key="item.classId" style="padding: 10px;cursor: pointer">

          <div style="margin-top:15px" @click="toDetail(item)">
            <el-card :body-style="{ padding: '0px'}" shadow="hover">
              <img
                  :src="item.eventBg?item.eventBg:'https://timesport-1313948175.cos.ap-shanghai.myqcloud.com/20240120/5084714d-581d-4711-a39b-15b84546a6ae.jpg'"
                  class="image" style="width: 100%;display: block;height: 266px">
              <div class="event-box" style="text-align: left;padding: 20px">
                <div class="event-name" style="display: flex;align-items: center;">
                  <a class="status1" v-if="item.status == 0">将开赛</a>
                  <a class="status2" v-if="item.status == 1">进行中</a>
                  <a class="status3"
                     v-if="item.status == 2">审核中
                  </a>
                  <a class="status4" v-if="item.status == 3">已发布</a>
                  <a style="font-size: 16px;
                      font-family: PingFangSC-Medium, PingFang SC;
                      white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      font-weight: 500;
                      color: #333333;
                      line-height: 22px;">
                    {{ item.eventName }}
                  </a>
                </div>
                <div class="bottom" style="display: flex;justify-content: start;align-items: center;margin-top: 10px;font-size: 14px;
                      font-family: PingFangSC-Regular, PingFang SC;
                      font-weight: 400;
                      color: #999999;
                      line-height: 20px;">
                  <span>
                    <i class="el-icon-location-outline" style="margin-right: 4px"></i>
							      <a class="el-footer-text">{{ item.provinceName }}-{{ item.cityName }}</a>
                  </span>
                  <span style="margin-left: 10px">
                    <i class="el-icon-date" style="margin-right: 4px"></i>
						      	<a class="el-footer-text">{{ item.startTime | formatStartTime }}</a>
                  </span>
                </div>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
      <el-pagination
          style="text-align: right;margin-top: 20px"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="current"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>

    <div v-else class="no-data">没有数据</div>

    <div style="width: 100%;margin-top: 60px;line-height: 60px;text-align: center">
      <a href="https://beian.miit.gov.cn/" style="font-size: 13px" target=" blank">豫ICP备2024078838号-1</a>
    </div>
  </div>
</template>

<script>
import {eventList} from '@/utils/request';

const date = new Date();
export default {
  data() {
    return {
      type: '0',
      data: {},
      eventName: '',
      teamId: null,
      tableData: [],
      current: 1,
      size: 20,
      total: 0,
    }
  },
  mounted() {

    this.loadTableData()
  },
  filters: {
    formatStartTime(v) {
      const dateObj = new Date(v);
      const year = dateObj.getFullYear(); // 2022
      const month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // 09
      const day = dateObj.getDate().toString().padStart(2, '0'); // 15

      return `${year}-${month}-${day}`;
    }
  },
  methods: {
    toDetail(item) {
      // if (item.status != 3){
      //   this.$message({
      //     type: "warning",
      //     message: "赛事未发布",
      //   });
      //   return
      // }
      if (item.publis == 2) {
        this.$router.push({
          path: '/pages/score/rank/single', query: {
            eventId: item.id
          }
        })
      } else {
        this.$router.push({
          path: '/pages/index/index', query: {
            eventId: item.id
          }
        })
      }
    },
    statusFormat(row, column) {
      if (row.status == 0) {
        return 'DNS';
      }
      if (row.status == 8) {
        return 'DNF';
      }
      if (row.status == 9) {
        return row.currentCheckpoint ? row.currentCheckpoint : '比赛中'
      }
      if (row.status == 10) {
        return '已完赛';
      }
      if (row.status == 7) {
        return '已退赛';
      }
      if (row.status == 6) {
        return '无效';
      }
      return ''
    },

    handleSizeChange(size) {
      this.size = size;
      this.loadTableData()
    },
    handleCurrentChange(current) {
      this.current = current;
      this.loadTableData()
    },
    loadTableData() {
      let params = {
        size: this.size,
        current: this.current,
        eventName: this.eventName
      }
      let that = this;
      eventList(params).then(
          (res) => {
            console.log('data', res)
            that.tableData = res.data.data.records;
            that.total = res.data.data.total;
          },
          (err) => {
            console.log(err);
          }
      )
    },
  },
};
</script>
<style scoped lang="scss">
.content {
  background: #F8F8F8;
  height: 100vh;

  .title-body {
    width: 1260px;
    margin: auto;
  }

  .title {
    padding-left: 32px;
    height: 72px;
    line-height: 72px;
    background: #FFFFFF;
    text-align: left;
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    border-bottom: 1px solid #eee;

  }

  .content-inner {
    width: 1260px;
    background: #fff;
    padding-bottom: 20px;

    .form {
      background: #fff;
      margin-top: 14px;
      padding: 14px 0;
      border-bottom: 1px solid #eee;

      .sreach-box {
        display: flex;

        .btn {
          background: #34C5F6;
          border: none;
          margin-left: 8px;
        }
      }
    }

    .table {
      margin-top: 14px;
    }

    .header_row {
      display: flex;
      flex-direction: row;
    }

    .addbutton {
      margin-right: 20px;
    }

    margin: auto;
  }

}


.grid-content {
  border-radius: 4px;
}

.el-form-item {
  margin-bottom: 0;
}

.no-data {
  padding-top: 300px;
}


.status1 {
  display: inline-block;
  width: 48px;
  height: 20px;
  background: rgba(249, 154, 48, 0.08);
  border: 1px solid rgba(249, 154, 48, 0.3);
  text-align: center;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #F99A30;
  margin-right: 16px;
}

.status2 {
  display: inline-block;
  width: 48px;
  height: 20px;
  background: rgba(246, 41, 41, 0.08);
  border: 1px solid rgba(246, 41, 41, 0.3);
  text-align: center;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #F62929;
  margin-right: 16px;
}

.status3 {
  display: inline-block;
  width: 48px;
  height: 20px;
  background: rgba(79, 202, 16, 0.08);
  border: 1px solid rgba(79, 202, 16, 0.3);
  text-align: center;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #4FCA10;
  margin-right: 16px;
}

.status4 {
  display: inline-block;
  width: 48px;
  height: 20px;
  background: rgba(61, 167, 246, 0.08);
  border: 1px solid rgba(61, 167, 246, 0.3);
  text-align: center;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3DA7F6;
  margin-right: 16px;
}
</style>
