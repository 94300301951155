import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUi from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// import canvasPoster from 'vue-canvas-poster-yufan'
// Vue.use(canvasPoster)
import VueCanvasPoster from 'vue-canvas-poster'
// 注册组件库
Vue.use(VueCanvasPoster)
Vue.use(ElementUi);
Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
