const padZero = function (value) {
    return String(value).padStart(2, "0");
};
const formatMilliseconds = function (value) {
    if (value <= 0) {
        return "";
    }
    if (value < 10) {
        return ".00" + value;
    }
    if (value < 100) {
        return ".0" + value;
    }
    return "." + String(value);
};

export function timeFilter(value) {
    if (value <= 0) {
        return "00:00:00";
    }
    let seconds = Math.ceil(value / 1000);
    if (seconds < 60) {
        return `00:00:${padZero(seconds)}`;
    }
    let minutes = Math.floor(seconds / 60);
    if (minutes < 60) {
        seconds %= 60;
        return `00:${padZero(minutes)}:${padZero(seconds)}`;
    }
    const hours = Math.floor(minutes / 60);
    seconds %= 60;
    minutes %= 60;
    return `${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)}`;
}

export function timeSecondFilter(value) {
    if (value <= 0) {
        return "00:00:00";
    }
    let seconds = Math.ceil(value / 1000);
    if (seconds < 60) {
        return `00:00:${padZero(seconds)}`;
    }
    let minutes = Math.floor(seconds / 60);
    if (minutes < 60) {
        seconds %= 60;
        return `00:${padZero(minutes)}:${padZero(seconds)}`;
    }
    const hours = Math.floor(minutes / 60);
    seconds %= 60;
    minutes %= 60;
    return `${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)}`;
}

export function timeFilter2(seconds) {
    if (seconds < 60) {
        return `${seconds}″`;
    }
    const minutes = Math.floor(seconds / 60);
    seconds %= 60;
    return `${minutes}′${seconds}″`;
}



