import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import eventList from '../views/event/list.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'eventList',
        component: Index
    } ,
    {
        path: '/pages/event/list',
        name: 'eventList',
        component: eventList
    } ,
    {
        path: '/pages/index/index',
        name: 'Index',
        component: Index
    },
    {
        path: '/pages/score/single/single',
        name: 'scoresSingle',
        component: () => import(/* webpackChunkName: "about" */ '../views/scores/Single.vue')
    },
    {
        path: '/pages/score/rank/single',
        name: 'rankSingle',
        component: () => import(/* webpackChunkName: "about" */ '../views/rank/Single.vue')
    }
    ,
    {
        path: '/pages/score/rank/team',
        name: 'rankTeam',
        component: () => import(/* webpackChunkName: "about" */ '../views/rank/Team.vue')
    }
    ,

    {
        path: '/pages/electron/electron',
        name: 'electron',
        component: () => import(/* webpackChunkName: "about" */ '../views/electron/electron.vue')
    },
    {
        path: '/pages/cert-preview/index',
        name: 'certPreview',
        component: () => import(/* webpackChunkName: "about" */ '../views/cert-preview/index.vue')
    }
    , {
        path: '/largeScreen',
        name: 'largeScreen',
        component: () => import(/* webpackChunkName: "about" */ '../views/large-screen/index.vue')
    }
    ,
    {
        path: '/pages/role-cert/role-cert',
        name: 'role-cert',
        component: () => import(/* webpackChunkName: "about" */ '../views/role-cert/role-cert.vue')
    }
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router
